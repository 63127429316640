<script setup>
import { computed } from "vue";
import SheetClose from "./SheetClose.vue";
import { GrSpinner } from "@/components/GrComponents";

const props = defineProps({
  position: {
    type: String,
    default: "right",
    validator: (value) => ["left", "right"].includes(value),
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const positionSheet = computed(() => `position-${props.position}`);
</script>

<template>
  <aside :class="positionSheet">
    <SheetClose>
      <button class="button-close-sheet">
        <img src="@/assets/img/icons-contracts/modal-header.svg" alt="close" />
      </button>
    </SheetClose>

    <div v-if="loading" class="container-loading">
      <div class="loading">
        <GrSpinner />
      </div>
    </div>

    <slot />
  </aside>
</template>

<style lang="scss" scoped>
aside {
  height: 100%;
  width: 515px;
  background: var(--gray-white);
  position: fixed;
  overflow: hidden auto;
  padding: 30px;
  transition: all 0.3s;
  animation-duration: 0.3s;
  animation-fill-mode: both;

  @include size(md) {
    width: 90%;
  }

  button {
    outline: none;

    height: var(--h-8);
    width: var(--w-8);

    border-radius: 999px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
    margin-bottom: 10px;
  }
}

.position-right {
  right: 0;
  border-radius: 20px 0 0 20px;
  animation-name: slide-in-right;
  button {
    margin-left: auto;
  }

  @keyframes slide-in-right {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }
}

.position-left {
  left: 0;
  border-radius: 0 20px 20px 0;
  animation-name: slide-in-left;

  .button-close-sheet {
    margin-left: auto;
  }

  @keyframes slide-in-left {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }
}

.container-loading {
  height: 100%;
  width: 100%;

  border-radius: 20px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;

  background-color: #ffffffbe;

  .loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
  }
}
</style>

<style>
aside::-webkit-scrollbar {
  width: 0 !important;
}
</style>
