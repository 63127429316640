<template>
    <BaseModal name="powerbi-export" size="lg" :title="$t('seller.sales.text_2613')" @shown="openModal">
      <b-row v-if="!loading">
        <b-col cols="12">
  
          <b-button variant="link" class="p-0 mb-2" @click="shouldEditCredentials = !shouldEditCredentials">
            {{ shouldEditCredentials ? 'Ocultar' : 'Editar credenciais' }}
          </b-button>
          <div class="w-full">
            <b-collapse v-model="shouldEditCredentials">
              <div class="w-full">
                <label for="tenantId" class="label-modal">Tenant Id.</label>
                <input v-model="tenantId" type="text" class="input-modal w-full-important" name="tenantId"
                  placeholder="Tenant Id.*" />
                <label for="clientId" class="label-modal">Client Id.</label>
                <input v-model="clientId" type="text" class="input-modal w-full-important" name="clientId"
                  placeholder="Client Id.*" />
                <label for="clientSecret" class="label-modal">Client Secret</label>
                <textarea v-model="clientSecret" class="textarea-modal w-full-important" name="clientSecret"
                  placeholder="Client Secret"></textarea>
                <GrButton variant="black" @click="saveCredentials" :disabled="loading">Conectar</GrButton>
              </div>
            </b-collapse>
  
            <!-- Exibe dropdown de datasets apenas após carregar -->
            <div v-if="shouldSelectDataset" class="mb-4">
              <hr />
              <label class="label-modal mt-4">Selecione um Dataset</label>
              <select v-model="selectedDataset" class="input-modal w-full-important">
                <option v-for="dataset in datasets" :key="dataset.id" :value="dataset.id">
                  {{ dataset.name }}
                </option>
              </select>
  
              <GrButton variant="black" @click="confirmeDataset" :disabled="!selectedDataset">
                Confirmar Seleção
              </GrButton>
  
            </div>
          </div>
          <div v-if="shouldSelectData">
            <div class="top-dados">
              <div>
                <p>{{ $t('seller.sales.text_2614') }}</p>
              </div>
            </div>
            <b-form novalidate>
              <b-row>
                <b-col cols="12">
                  <b-form-group :label="$t('seller.sales.text_2615')" label-for="method" class="form-relative">
                    <a href="#" @click.prevent="selecionarTodos('dados')" class="btn-todos">{{
                      $t('seller.sales.text_2616')
                      }}</a>
                    <multiselect v-model="dados" :placeholder="$t('seller.sales.text_2617')" label="name" track-by="id"
                      :options="dice_list" :multiple="true" :taggable="false" :clear-on-select="false"
                      :close-on-select="false" class="" :selectLabel="$t('seller.sales.text_2618')"
                      :deselectLabel="$t('seller.sales.text_2619')" selectedLabel="✔️">
                      <template v-slot:noResult>
                        <span>{{ $t('seller.sales.text_2620') }}</span>
                      </template>
                    </multiselect>
                  </b-form-group>
                </b-col>
              </b-row>
  
              <b-row v-if="advanced_list.length">
                <b-col cols="12">
                  <b-form-group :label="$t('seller.sales.text_2621')" label-for="method" class="form-relative">
                    <a href="#" @click.prevent="selecionarTodos('advanced')" class="btn-todos">{{
                      $t('seller.sales.text_2616') }}</a>
                    <multiselect v-model="dados2" :placeholder="$t('seller.sales.text_2621')" label="meta_key"
                      track-by="meta_key" :options="advanced_list" :multiple="true" :taggable="false"
                      :clear-on-select="false" :close-on-select="false" class=""
                      :selectLabel="$t('seller.sales.text_2618')" :deselectLabel="$t('seller.sales.text_2619')"
                      selectedLabel="✔️">
                      <template v-slot:noResult>
                        <span>{{ $t('seller.sales.text_2620') }}</span>
                      </template>
                    </multiselect>
                  </b-form-group>
                </b-col>
              </b-row>
  
              <b-row>
                <b-col>
                  <b-form-group :label="$t('seller.sales.label-filter-date')" label-for="date" class="form-relative">
                    <date-range-picker ref="picker" v-model="dateRange" opens="right" :locale-data="localeDate"
                      :showWeekNumbers="false" :showDropdowns="true" :autoApply="false" :ranges="dateRanges"
                      :class="{ 'invalid-date-filter': isInvalidDate }" class="field-date" @update="updateDate">
                      <template v-if="dateRange.startDate && dateRange.endDate" v-slot:input="picker"
                        style="min-width: 350px">
                        {{ picker.startDate | date }} - {{ picker.endDate | date }}
                      </template>
  
                      <template v-else v-slot:input class="btn-table" :title="$t('views.seller.sales.text_1418')">
                        {{ $t("views.seller.sales.text_1419") }}
                      </template>
                    </date-range-picker>
                  </b-form-group>
  
                  <span v-if="isInvalidDate" class="message-invalid-date">
                    {{ $t('seller.sales.invalid-filter-date-message') }}
                  </span>
                </b-col>
  
                <b-col>
                  <b-form-group :label="$t('seller.sales.text_2622')">
                    <b-form-input v-model="email" :placeholder="$t('seller.sales.text_2623')"></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </div>
        </b-col>
      </b-row>
  
      <template v-slot:footer="{ cancel }">
        <BaseButton variant="link-info" class="mr-4" @click="cancel">
          {{ $t('seller.sales.text_2624') }}
        </BaseButton>
        <BaseButton variant="black" @click="onSubmit" :disabled="loading || isInvalidDate || !selectedDataset">
          {{ $t('seller.sales.text_2625') }}
        </BaseButton>
      </template>
  
      <div class="d-flex justify-content-center" v-if="loading">
        <b-spinner label="Loading..."></b-spinner>
      </div>
    </BaseModal>
  
  </template>
  <script>
  import Multiselect from "vue-multiselect";
  import DateRangePicker from "vue2-daterange-picker"
  
  import SaleService from "@/services/resources/SaleService";
  import moment from 'moment-timezone';
  import { GrButton } from "@/components/GrComponents";
  import axios from "axios";
  import Cookies from "js-cookie"
  
  const serviceSale = SaleService.build();
  
  export default {
    props: ["filters", "accessToken"],
    components: {
      Multiselect,
      DateRangePicker,
      GrButton
    },
    data() {
      return {
        email: "",
        loading: false,
        dados: [],
        dados2: [],
        advanced_list: [],
        dice_list: [
          { id: "product_name", name: this.$t("views.seller.sales.modal_export.text_100") },
          { id: "offer_id", name: this.$t("views.seller.sales.modal_export.text_118") },
          { id: "offer_name", name: this.$t("views.seller.sales.modal_export.text_117") },
          { id: "client_name", name: this.$t("views.seller.sales.modal_export.text_101") },
          { id: "client_email", name: this.$t("views.seller.sales.modal_export.text_102") },
          { id: "id", name: this.$t("views.seller.sales.modal_export.text_103") },
          { id: "date", name: this.$t("views.seller.sales.modal_export.text_104") },
          { id: "amount", name: this.$t("views.seller.sales.modal_export.text_105") },
          { id: "balance", name: this.$t("views.seller.sales.modal_export.text_106") },
          { id: "client_document", name: this.$t("views.seller.sales.modal_export.text_107") },
          { id: "client_document_type", name: this.$t("views.seller.sales.modal_export.text_108") },
          { id: "client_address", name: this.$t("views.seller.sales.modal_export.text_109") },
          { id: "client_cellphone", name: this.$t("views.seller.sales.modal_export.text_110") },
          { id: "method", name: this.$t("views.seller.sales.modal_export.text_111") },
          { id: "status", name: this.$t("views.seller.sales.modal_export.text_112") },
          { id: "affiliate", name: this.$t("views.seller.sales.modal_export.text_113") },
          { id: "co_producer", name: this.$t("views.seller.sales.modal_export.text_114") },
          { id: "total_affiliate_manager", name: this.$t("views.seller.sales.modal_export.text_121") },
          { id: "affiliate_name", name: this.$t("views.seller.sales.modal_export.text_122") },
          { id: "pay_day", name: this.$t("views.seller.sales.modal_export.text_115") },
          { id: "installments", name: this.$t("views.seller.sales.modal_export.text_119") },
          { id: "installments_active_contract", name: this.$t("views.seller.sales.modal_export.text_120") },
        ],
        dateRange: {
          startDate: moment().startOf("day"),
          endDate: moment().endOf("day"),
        },
        localeDate: {
          direction: "ltr",
          format: "dd/mm/yyyy",
          separator: " - ",
          applyLabel: this.$t('locale_date.apply_label'),
          cancelLabel: this.$t('locale_date.cancel_label'),
          weekLabel: "W",
          customRangeLabel: "Custom Range",
          daysOfWeek: this.$t('locale_date.days_of_week'),
          monthNames: this.$t('locale_date.month_names'),
          firstDay: 0,
        },
        datasets: [],
        shouldEditCredentials: true,
        shouldSelectDataset: false,
        shouldSelectData: false,
        selectedDataset: null,
        clientId: '',
        tenantId: '',
        clientSecret: '',
        parsedAccessToken: null
      };
    },
    async mounted() {
        await this.getCredentials();
        if (this.accessToken) {
            this.shouldEditCredentials = false;
            this.shouldSelectDataset = true;
            await this.getDatasets();
        }

        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get("code");

        if (code) {
            try {
                if(this.getEnv == "dev"){
                  this.parsedAccessToken = process.env.VUE_CODE_DEV;
                } else {
                  const response = await serviceSale.post('/powerbi/callback', { code });
                  this.parsedAccessToken = response.access_token;
                }   
            } catch (error) {
                console.error("Error getting access token:", error);
            }
        }
    },
    computed: {
      getExportSales() {
        return this.$store.getters['getExportSales'];
      },
  
      isInvalidDate() {
        return this.totalDays > 180;
      },
  
      totalDays() {
        if (this.dateRange.startDate && this.dateRange.endDate) {
          return moment(this.dateRange.endDate).diff(moment(this.dateRange.startDate), 'days') + 1;
        }
        return 0;
      },
  
      dateRanges() {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
  
        const yesterday = new Date();
        yesterday.setDate(today.getDate() - 1);
        yesterday.setHours(0, 0, 0, 0);
  
        const lastWeek = new Date();
        lastWeek.setDate(today.getDate() - 7);
        lastWeek.setHours(0, 0, 0, 0);
  
        return {
          [this.$t("locale_date.today")]: [today, today],
          [this.$t("locale_date.yesterday")]: [yesterday, yesterday],
          [this.$t("locale_date.last_week")]: [lastWeek, today],
          [this.$t("locale_date.current_month")]: [
            new Date(today.getFullYear(), today.getMonth(), 1),
            new Date(today.getFullYear(), today.getMonth(), 30),
          ],
          [this.$t("locale_date.last_month")]: [
            new Date(today.getFullYear(), today.getMonth() - 1, 1),
            new Date(today.getFullYear(), today.getMonth(), 0),
          ],
          [this.$t('locale_date.current_year')]: [
            new Date(today.getFullYear(), 0, 1),
            new Date(today.getFullYear(), 11, 31),
          ],
          [this.$t("locale_date.every_period")]: [new Date(2020, 0, 1), new Date(today)],
        };
      },
      userLogged() {
        return this.$store.getters.getProfile;
      },
      getEnv() {
        return process.env.VUE_APP_ENVIRONMENT || 'prod';
      }
    },
    methods: {
      updateDate() {
        const date_start = moment(this.dateRange.startDate);
        const date_end = moment(this.dateRange.endDate);
  
        this.filters = {
          ...this.filters,
          date_start,
          date_end,
        };
      },
  
      selecionarTodos(type) {
        switch (type) {
          case "dados": {
            this.dados = [
              { id: "product_name", name: "Nome do Produto" },
              { id: "offer_id", name: "Código da Oferta" },
              { id: "offer_name", name: "Nome da Oferta" },
              { id: "client_name", name: "Nome do Cliente" },
              { id: "client_email", name: "E-mail do Cliente" },
              { id: "id", name: "Código da Venda" },
              { id: "date", name: "Data" },
              { id: "amount", name: "Valor Bruto" },
              { id: "balance", name: "Valor Líquido" },
              { id: "client_cpf_cnpj", name: "Documento" },
              { id: "client_address", name: "Endereço do Cliente" },
              { id: "client_cellphone", name: "Telefone" },
              { id: "method", name: "Metodo de Pagamento" },
              { id: "status", name: "Status da Venda" },
              { id: "affiliate", name: "Total afiliado" },
              { id: "co_producer", name: "Total Co-Produtor" },
              { id: "total_affiliate_manager", name: "Total gerente afiliado" },
              { id: "affiliate_name", name: "Nome do afiliado" },
              { id: "pay_day", name: "Data de pagamento" },
              { id: 'client_document', name: 'Documento' },
              { id: 'client_document_type', name: 'Tipo do documento' },
              { id: "installments", name: "Parcelas contrato assinatura" },
              { id: "installments_active_contract", name: "Parcela atual" },
            ];
            break;
          }
          case "advanced": {
            this.dados2 = this.advanced_list;
            break;
          }
        }
      },
      async onSubmit() {
        this.$bvToast.toast(
          'Sua exportação para PowerBI está sendo processada.',
          {
            title: 'PowerBI',
            variant: "info",
            autoHideDelay: 5000,
            appendToast: true,
          }
        );
        this.loading = false;
        setTimeout(() => {
            this.$bvModal.hide("powerbi-export");
        }, 500);
        return;
        if (this.getExportSales) {
          this.$bvToast.toast(this.$t('seller.sales.text_2660'), {
            title: this.$t('seller.sales.text_2613'),
            variant: "info",
            autoHideDelay: 5000,
            appendToast: true,
          });
          return;
        }
  
        this.loading = true;
  
        let basic = [];
        let dados = this.dados;
        for (let i = 0; i < dados.length; i++) {
          basic.push(dados[i].id);
        }
        let advanced = [];
        let dados2 = this.dados2;
        for (let i = 0; i < dados2.length; i++) {
          advanced.push(dados2[i].meta_key);
        }
  
        let data = {
          id: "/export",
          basic: basic,
          metas: advanced,
          email: this.email,
          filters: JSON.stringify(this.filters),
        };
  
        await serviceSale
          .createId(data)
          .then(() => {
            this.$bvToast.toast(
              this.$t('seller.sales.text_2626'),
              {
                title: this.$t('seller.sales.text_2613'),
                variant: "info",
                autoHideDelay: 5000,
                appendToast: true,
              }
            );
          })
          .catch((err) => {
            if (err?.response?.status !== 422) {
              this.$bvToast.toast(this.$t('seller.sales.text_2831'), {
                title: this.$t('seller.sales.text_2613'),
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
            }
          })
          .finally(() => {
            this.loading = false;
            this.$bvModal.hide("powerbi-export");
          });
  
      },
      async saveCredentials() {
        try {
          this.loading = true;
          this.shouldEditCredentials = false;
          this.shouldSelectDataset = true;

          if(this.tenantId == '' || this.clientId == '' || (this.clientSecret == '' && this.clientSecretHidden == '')) {
            this.$bvToast.toast('Preencha todos os campos \'tenantId\', \'clientId\',\'clientSecret\'', {
                title: 'Campos vazios',
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });

              return;
          }


          const response = await serviceSale.post('/powerbi', {
            tenant_id: this.tenantId,
            client_id: this.clientId,
            client_secret: this.clientSecret || this.clientSecretHidden
          });

          if (this.getEnv != 'dev') {
            const data  = await serviceSale.get('/powerbi/redirect');
              if(data.auth_url){
                  this.shouldEditCredentials = false;
                  this.shouldSelectDataset = true;
                  window.location.href = data.auth_url;
              }
          }else {
              
            setTimeout(() => {
                  this.shouldEditCredentials = false;
                  this.shouldSelectDataset = true;
                  window.location.href = `${process.env.VUE_POWERBI_REDIRECT}?code=${process.env.VUE_CODE_DEV}`
            },1000);

          }

        } catch (error) {
          console.error("Error during authentication redirect:", error);
        } finally {
          this.loading=false;
        }
      },
      fetchAdicionais() {
        this.loading = true;
        serviceSale
          .read("/meta/keys")
          .then((response) => {
            this.advanced_list = response;
          })
          .catch((err) => {
            console.log(err);
            this.products = [];
          })
          .finally(() => {
            this.loading = false;
          });
      },
      openModal() {
        this.dados = [];
        this.dados2 = [];
  
        this.updateDate();
        this.email = this.$store.getters.getProfile.email;
        this.fetchAdicionais();
      },
      confirmeDataset() {
        this.shouldSelectData = true;
      },
      async getCredentials() {
        try {
          const data = await serviceSale.get('/powerbi/credential');
          this.clientId = data.client_id || '';
          this.tenantId = data.tenant_id || '';
          this.clientSecret = data.client_secret || '';
        } catch (error) {
          console.log(error);
        }
      },
      async getDatasets() {
        
        try {
            if(this.getEnv != "dev"){ 

              axios.get('/api/sale/powerbi/datasets', {
                headers: {
                  'Authorization': `${Cookies.get('access_token')}`, 
                  'X-PowerBI-Token': this.parsedAccessToken,
                  'Content-Type': 'application/json'
                }
              })
              .then(response => {
                  this.datasets = response.data;
              })
              .catch(error => {
                  console.error('Error fetching Power BI datasets:', error);
              });
            } else {
              this.datasets = [{ id: 1, name: 'Dataset gestão' }, { id: 2, name: 'Dataset administrativo' }]
            }
        } catch (error) {
          console.error("Error fetching datasets:", error.message);
        }
  
      }
    },
  };
  </script>
  
  <style scoped>
  p {
    margin: 0;
    font-size: 14px;
    font-weight: normal;
    color: var(--gray-200);
    line-height: 1.5;
  }
  
  p+p {
    margin-top: 5px;
  }
  
  .link-documentacao {
    padding: 0 25px !important;
    height: 40px !important;
    font-size: 12px;
  }
  
  .top-dados {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  @media screen and (max-width: 768px) {
    .top-dados {
      display: block;
    }
  
    .top-dados #limpar {
      margin-top: 15px;
    }
  }
  
  .form-relative {
    position: relative;
  }
  
  .btn-todos {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 14px;
    color: var(--gray-200);
  }
  
  .message-invalid-date {
    position: absolute;
    bottom: -10px;
    font-size: 13px;
    color: var(--red-500);
  }
  
  .field-date {
    width: 100%;
  }
  </style>
  
  <style>
  .invalid-date-filter .form-control {
    border-color: var(--red-500) !important;
  
    &:hover {
      border-color: var(--red-500) !important;
    }
  }
  
  .w-full-important {
    width: 100% !important;
  }
  </style>