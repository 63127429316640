import { render, staticRenderFns } from "./Sheet.vue?vue&type=template&id=670e68e5&scoped=true"
import script from "./Sheet.vue?vue&type=script&setup=true&lang=js"
export * from "./Sheet.vue?vue&type=script&setup=true&lang=js"
import style0 from "./Sheet.vue?vue&type=style&index=0&id=670e68e5&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "670e68e5",
  null
  
)

export default component.exports